import { withScope } from '@sentry/solidstart';
import { action } from '@solidjs/router';
import { getUserAgent, isPerformanceRestrictedUserAgent } from '@troon/user-agent';
import type forms from '../formspree.json';

export function formspreeAction(project: string, form: keyof typeof forms.forms) {
	return action(async (data: FormData) => {
		const body = new FormData();
		for (const key of data.keys()) {
			let value = data.get(key);
			if (typeof value === 'string') {
				value = value.trim();
			}
			if (!key.startsWith('_') && value) {
				body.set(key, value);
			}
		}

		if (isPerformanceRestrictedUserAgent(getUserAgent())) {
			throw { error: {} };
		}

		const res = await fetch(`https://formspree.io/p/${project}/f/${form}`, {
			method: 'POST',
			body,
			headers: { accept: 'application/json' },
		});

		const json = await res.json();
		if (!res.ok) {
			withScope((scope) => {
				scope.setExtra('field-errors', json.errors);
				scope.setExtra('status', res.status);
				scope.captureMessage(`Invalid form submission for ${form}`, 'warning');
			});
			throw json;
		}
		return json;
	}, form);
}
